import React, { useState, useEffect, useRef, useContext } from 'react'
import { useForm } from "react-hook-form"
import Button from '../../components/button'
import { useUser } from '../../hooks/useUser'
import { useApi } from '../../hooks/useApi'
import UiStoreContext from '../../contexts/uiStoreContext'
import 'twin.macro'
import AccountLayout from './components/accountSettingsLayout'
import {
	faSave,
	faTimes,
} from '@fortawesome/free-solid-svg-icons'
import PasswordChecklist from "../../components/passwordChecklist"
import passwordValidator from 'password-validator'
import { toast, Zoom } from 'react-toastify'

// create a password schema
const schema = new passwordValidator();

schema
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols()


const Password = ({...props}) => {

	const { callApi } = useApi()
	const { user, changePassword } = useUser()
	const { register, errors, reset, watch, formState: {dirtyFields}, handleSubmit } = useForm({
		// mode: 'onChange',
		defaultValues: {
		 	email: user && user.email
		}
	});
	const [error, setError] = useState();
	const uiStore = useContext(UiStoreContext);
	const [spin, setSpin] = useState(false)
	const watchNewPassword = watch("newPassword")

	const onSubmit = async (data) => {
		try {
			setSpin(true)
			setError(null)
			await changePassword(data.password, data.newPassword)
			resetFormAndError()
			toast.info('Password changed successfully!', {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				transition: Zoom
			});
		}
		catch (err) {
			console.log(err)
			setError(err);
		}
		finally {
			setSpin(false)
		}
	};

	const resetFormAndError = (e) => {
		setError(null)
		reset()
	}

	if (!user) {
		return null;
	}

	return <AccountLayout tab="password" {...props}>
		<div tw="">
			{}
			<form onSubmit={handleSubmit(onSubmit)}>
				<label htmlFor="password">Current password</label>
				<input
					name="password"
					type="password"
					placeholder="Current Password"
					ref={register({
						required:'Current password is required'
					})}
				></input>
				{errors.password && <div className="error">{errors.password.message}</div>}
				<label htmlFor="newPassword">New password</label>
				<input 
					name="newPassword"
					type="password"
					placeholder="New Password"
					ref={register({
						required: 'New password is required',
						validate: value => schema.validate(value)
					})}
				/>
				{errors.newPassword && <div className="error">{errors.newPassword.message}</div>}
				{ watchNewPassword && !schema.validate(watchNewPassword) && <PasswordChecklist schema={schema} password={watchNewPassword} tw="mb-4"/>}
				<label htmlFor="repeatNewPassword">Repeat new password</label>
				<input 
					name="repeatNewPassword"
					type="password"
					placeholder="Repeat New Password"
					ref={register({
						required: 'Repeat new password is required',
						validate: value => value === watchNewPassword || 'Passwords do not match'
					})}
				/>
				{errors.repeatNewPassword && <div className="error">{errors.repeatNewPassword.message}</div>}

				{ error && <div tw="text-red mb-4 w-full pl-1">{error.message}</div>}

				<div tw="flex flex-col md:flex-row-reverse mt-4">
					<Button icon={faSave} type="submit" special disabled={false} spin={spin}>Save New Password</Button>
					<Button icon={faTimes} type="button" tw="mt-2 md:mt-0 md:mr-2" onClick={resetFormAndError} disabled={false}>Cancel</Button>
				</div>
			</form>

		</div>
	</AccountLayout>
};

export default Password